import { selectFilter, dateFilter, textFilter } from 'react-bootstrap-table2-filter';
import kebabToTitleCase from '../../../../utils/kebabToTitleCase';
import DataColumn from '../../../../components/table/DataColumn';
import KioskStatus from '../../../../common/Kiosk/KioskStatus';
import stringWithSpacesUppercase from '../../../../utils/stringWithSpacesUppercase';
import IncidentReportDataExcerpt, { MachineStatusExcerpt } from '../IncidentReportDataExcerpt';
import IncidentReportsKeys from '../IncidentReportsKeys';
import IncidentReportStrings from '../IncidentReportStrings';
import IncidentReportHelper from '../IncidentReportHelper';
import sortCaret from '../../../../components/table/ColumnSortIcon';
import IncidentResolutionStatus from '../IncidentResolutionStatus';
import ResolutionBadge from '../ResolutionBadge';

const kioskStatusOptions = Object.entries(KioskStatus).reduce((options, [key, value]) => {
  options[value] = stringWithSpacesUppercase(key);
  return options;
}, {});

const resolutionStatusOptions = Object.entries(IncidentResolutionStatus).reduce((options, [key, value]) => {
  options[value] = stringWithSpacesUppercase(key);
  return options;
}, {});

const IncidentReportsTableConfig = (): DataColumn<IncidentReportDataExcerpt, IncidentReportsKeys>[] => {
  return [
    {
      dataField: 'date',
      text: 'Date',
      formatter: (cell) =>
        cell ? `${IncidentReportHelper.dateString(cell)}*` : IncidentReportStrings.MissingFromReport,
      filter: dateFilter({}),
      sort: true,
      sortCaret: (order: 'asc' | 'desc') => sortCaret(order)
    },
    {
      dataField: 'resolution',
      text: 'Status',
      formatter: (cell) => <ResolutionBadge resolution={cell} />,
      filter: selectFilter({
        options: resolutionStatusOptions
      }),
      sort: true,
      sortCaret: (order: 'asc' | 'desc') => sortCaret(order)
    },
    {
      dataField: 'kioskId',
      text: 'Kiosk',
      formatter: (cell) => cell
    },
    {
      dataField: 'id',
      text: 'ID',
      formatter: (cell) => cell ?? IncidentReportStrings.MissingFromReport,
      filter: textFilter({}),
      sort: true,
      sortCaret: (order: 'asc' | 'desc') => sortCaret(order)
    },
    {
        dataField: 'status',
        text: 'Version',
        formatter: (cell) => cell?.appVersion ?? IncidentReportStrings.MissingFromReport,
        sort: true,
        sortCaret: (order: 'asc' | 'desc') => sortCaret(order)
      },
    {
      dataField: 'status.kioskStatus' as keyof MachineStatusExcerpt,
      text: 'Kiosk Status',
      formatter: (cell) => cell && kebabToTitleCase(cell),
      filter: selectFilter({
        options: kioskStatusOptions,
        defaultValue: KioskStatus.Deployed
      }),
      sort: true,
      sortCaret: (order: 'asc' | 'desc') => sortCaret(order)
    },
    {
      dataField: 'error',
      text: 'Error',
      formatter: (cell) => cell?.name ?? IncidentReportStrings.MissingFromReport
    },
    {
      dataField: 'error',
      text: 'Cause',
      formatter: (cell, row) => {
        if (cell?.cause?.message) return cell?.cause?.message;
        if (row?.eventNotification) return row?.eventNotification?.cause;
        if (cell?.message)
          return (
            <ul>
              <li>{IncidentReportStrings.TechnicalCauseMissing}</li>
              {cell?.message}
            </ul>
          );
        return IncidentReportStrings.MissingFromReport;
      }
    }
  ];
};

export default IncidentReportsTableConfig;
